import {
  Injectable,
  Inject,
  EventEmitter,
  Output,
  PLATFORM_ID,
  Renderer2,
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Title, Meta } from "@angular/platform-browser";
import { MasterApiService } from "./master-api.service";
import { isPlatformBrowser, isPlatformServer, Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { catchError } from "rxjs/operators";
// import { type } from 'os';

var tinycolor = require("tinycolor2");

declare var jquery: any;
declare var $: any;

interface Script {
  src: string;
  loaded: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  // public _hostName = 'https://zahrastore.net/affiliate-develop/api-develop/';
  // public _ImageUrl = 'https://zahrastore.net/affiliate/api/';
  // public _placeUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/places.php?action=';
  // public _pagesUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/pages.php?action=';
  // public _settingsUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/settings.php?action=';
  // public _brandsUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/trademarks.php?action=';
  // public _notificationUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/notifications.php?action=';
  // public _offersUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/offers.php?action=';
  // public _cartsUrl = 'https://zahrastore.net/affiliate-develop/api-develop/web-api/carts.php?action=';

  public _hostName = this.masterApiService.masterData.site_url + "/web-api/";
  public _ImageUrl = this.masterApiService.masterData.img_url + "/";
  public _imageUrlPlaces;
  public _placeUrl = this._hostName + "places.php?action=";
  isRtl: boolean = localStorage.getItem("front-lang") == "ar" ? true : false;
  public _pagesUrl = this._hostName + "pages.php?action=";
  public _settingsUrl = this._hostName + "settings.php?action=";
  public _brandsUrl = this._hostName + "trademarks.php?action=";
  public _notificationUrl = this._hostName + "notifications.php?action=";
  public _offersUrl = this._hostName + "offers.php?action=";
  public _cartsUrl = this._hostName + "carts.php?action=";
  private _slideUrl = this._hostName + "banners.php?action=";
  public _lang_code =
    localStorage.getItem("front-lang") != null
      ? localStorage.getItem("front-lang")
      : "ar";

  public settingData: any;

  public _scripts: Script[] = [];

  public _googleStoreUrl =
    "https://play.google.com/store/apps/details?id=app.zahra.egypt";
  public _appStoreUrl = "https://www.apple.com/eg/app-store/";

  // GET GRID LAYOUT
  public cart_group_id: string = "";
  public noteOn = false;
  @Output() changeCartUpdated: EventEmitter<any> = new EventEmitter();
  UpdatedSetting = new BehaviorSubject<any>("");
  UpdatedCart = new BehaviorSubject<any>("");
  UpdatedChecckOut = new BehaviorSubject<any>("");
  Updatedheaderone = new BehaviorSubject<any>("");

  public_url = "web-api/common.php?action=";
  public mainTitle: string = "";
  public description: string = "";
  public keyWords: string = "";
  // public currency = {
  //   ar: "جنيه",
  //   en: "LE",
  // };

  currencyType: string = "";

  // public dataContactInfo = new BehaviorSubject<DataContact>({} as DataContact);

  constructor(
    private router: Router,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
    public masterApiService: MasterApiService,
    public location: Location,
    public activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.masterApiService.masterData.user_name != "GOGET") {
      console.log(this._ImageUrl);
      this._imageUrlPlaces = this._ImageUrl.replace(
        "/" + this.masterApiService.masterData.user_name,
        "/malyaan"
      );
    } else {
      this._imageUrlPlaces = this._ImageUrl;
    }
  }

  setTitle(
    subTitle: string = "",
    isHomePage: boolean = false,
    isBrandCategory: boolean = false,
    teet = ""
  ) {
    this.masterApiService.getCurrentDataContact().subscribe((dataContact) => {
      if (!dataContact) {
        console.error("Data contact information is undefined or null");
        return;
      }
      this.settingData = dataContact;
      localStorage.setItem(
        "front_name",
        dataContact["tag_title"] == ""
          ? dataContact["front_name"]
          : dataContact["tag_title"]
      );

      this.title.setTitle(
        dataContact["tag_title"] == ""
          ? dataContact["front_name"]
          : dataContact["tag_title"] + " - " + subTitle
      );
      console.log("subTitle", subTitle);
      if (!this.document.URL.includes("product")) {
        this.setMeta(
          "description",
          dataContact["meta_description"].replace(/<[^>]*>/g, "")
        );
        this.setMeta("keywords", dataContact["meta_keywords"]);
        this.setMeta("", this.document.URL, true, "og:url");
        this.setMeta(
          "",
          dataContact["meta_description"].replace(/<[^>]*>/g, ""),
          true,
          "og:description"
        );
        this.setMeta("", "store", true, "og:type");
        this.setMeta("", localStorage.getItem("front-lang"), true, "og:locale");
        this.setMeta(
          "",
          localStorage.getItem("front_name") + " - " + subTitle,
          true,
          "og:title"
        );
        this.setMeta(
          "",
          localStorage.getItem("front_name"),
          true,
          "og:siteName"
        );
        var metaTag = document.querySelector("meta[name=':locale:alternate']");
        console.log("finish", teet);
        if (!metaTag) {
          this.meta.addTag({ name: ":locale:alternate", content: "ar_AR" });
          this.meta.addTag({ name: ":locale:alternate", content: "en_EN" });
        }
      }
    });
  }

  setTitleOnly(subTitle: string) {
    this.title.setTitle(localStorage.getItem("front_name") + " - " + subTitle);
  }

  setMeta(
    name: string = "",
    content: string,
    propertyStatus: boolean = false,
    property: string = ""
  ) {
    if (!propertyStatus) {
      this.meta.updateTag({
        name: name,
        content: content,
      });
    } else {
      this.meta.updateTag({
        property: property,
        content: content,
      });
    }
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  loadScripts(tag, scripts, loadOnce?: boolean) {
    loadOnce = loadOnce || false;

    scripts.forEach((script: string) => {
      if (!this._scripts[script]) {
        this._scripts[script] = { src: script, loaded: false };
      }
    });

    let promises: any[] = [];
    scripts.forEach((script) =>
      promises.push(this.loadScript(tag, script, loadOnce))
    );

    return Promise.all(promises);
  }

  loadScript(tag, src: string, loadOnce?: boolean) {
    loadOnce = loadOnce || false;

    if (!this._scripts[src]) {
      this._scripts[src] = { src: src, loaded: false };
    }

    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this._scripts[src].loaded && loadOnce) {
        resolve({ src: src, loaded: true });
      } else {
        // load script tag
        let scriptTag = $("<script/>")
          .attr("type", "text/javascript")
          .attr("src", this._scripts[src].src);

        $(tag).append(scriptTag);

        this._scripts[src] = { src: src, loaded: true };
        resolve({ src: src, loaded: true });
      }
    });
  }

  changeCart(name) {
    this.changeCartUpdated.emit();
  }

  getHomeSlider() {
    return this.http.get<any>(
      this._slideUrl +
        "getBanners&start=0" +
        "&aItemsPerPage=" +
        50 +
        "&searchKey="
    );
  }
  getPages() {
    return this.http.get<any>(
      this._pagesUrl +
        "getPages&start=0" +
        "&aItemsPerPage=" +
        50 +
        "&searchKey="
    );
  }

  getOffersList() {
    return this.http.get<any>(this._offersUrl + "getOffersList");
  }

  getNotificationsList(aItemsPerPage = 5) {
    return this.http.get<any>(
      this._notificationUrl +
        "getNotificationsList&start=0" +
        "&aItemsPerPage=" +
        aItemsPerPage
    );
  }

  deleteNotification(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(
      this._notificationUrl + "deleteNotification",
      body
    );
  }

  readNotification(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(
      this._notificationUrl + "readNotification",
      body
    );
  }

  getNotificationDetails(notification_id) {
    return this.http.get<any>(
      this._notificationUrl +
        "getNotificationDetails&notification_id=" +
        notification_id
    );
  }

  getUnReadNotificationNum() {
    return this.http.get<any>(
      this._notificationUrl + "getUnReadNotificationNum"
    );
  }

  getAllContanctInfo(): Observable<any> {
    return this.http.get<any>(this._settingsUrl + "getAllContanctInfo").pipe(
      catchError((err) => {
        console.error("Error fetching contact info", err);
        return of(null);
      })
    );
  }

  getAllBrands(search = "", id = 0) {
    return this.http.get<any>(
      this._brandsUrl +
        "getTrademarks&start=0&aItemsPerPage=5000&searchTitle=" +
        search +
        "&id=" +
        id
    );
  }

  getPageDetails(page_id) {
    return this.http.get<any>(
      this._pagesUrl + "getPageDetails" + "&page_id=" + page_id
    );
  }

  getPlaces(parent_id, city_id) {
    return this.http.get<any>(
      this._placeUrl +
        "getPlaces&start=0" +
        "&aItemsPerPage=" +
        500 +
        "&parent_id=" +
        parent_id +
        "&city_id=" +
        city_id
    );
  }

  getShowPrice() {
    return localStorage.getItem("show_price");
  }

  getCart() {
    return this.http.get<any>(this._cartsUrl + "getCart");
  }

  addToCart(data) {
    // console.log(data);
    if (
      localStorage.getItem("clientToken") != null &&
      localStorage.getItem("clientToken") != ""
    ) {
      // console.log("CARD FROM ADD TO CART");
      this.changeCart("");
      this.getCart();
    }

    let body = JSON.stringify(data);
    // console.log(body);
    return this.http.post<any>(this._cartsUrl + "addToCart", body);
  }

  emptyCart(invoiceEditId: any, invoiceId: any, cartId: any, type: any) {
    let obj = {
      invoice_edit_id: invoiceEditId,
      invoice_id: invoiceId,
      cart_id: cartId,
      // "canceled" OR "request_is_done"
      type: type,
    };
    if (localStorage.getItem("cart_group_id") !== "0") {
      localStorage.setItem("cart_group_id", "0");
    }
    let body = JSON.stringify(obj);
    return this.http.post<any>(this._cartsUrl + "emptyCart", body);
  }

  limitString(txt: string, txtLength: number) {
    if (txt.length > txtLength) {
      const str = txt.substring(0, txtLength);
      return str + " ...";
    } else {
      return txt;
    }
  }

  updateCartItem(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._cartsUrl + "updateCartItem", body);
  }

  deleteCartItem(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._cartsUrl + "deleteCartItem", body);
  }

  getNoteDetails(id) {
    return this.http.get<any>(this._pagesUrl + "getNoteDetails&id=" + id);
  }

  tinyColor(color: string) {
    return tinycolor(color).isLight() ? "#000" : "#fff";
  }

  getAllPaymentMethods(contactInfoData: any) {
    let paymentInfo = [];
    if (contactInfoData.payment_method_home == 1) {
      paymentInfo.push({
        name: contactInfoData.payment_method_home_name,
        price: contactInfoData.payment_method_home_price,
        text: contactInfoData.payment_method_home_text,
        key: "payment_method_home",
      });
    }
    if (contactInfoData.payment_method_bank == 1) {
      paymentInfo.push({
        name: contactInfoData.payment_method_bank_name,
        price: contactInfoData.payment_method_bank_price,
        text: contactInfoData.payment_method_bank_text,
        key: "payment_method_bank",
      });
    }
    if (contactInfoData.payment_method_card == 1) {
      paymentInfo.push({
        name: contactInfoData.payment_method_card_name,
        price: contactInfoData.payment_method_card_price,
        text: contactInfoData.payment_method_card_text,
        key: "payment_method_card",
      });
    }
    return paymentInfo;
  }

  getAllSupportAndBlogCategory(): Observable<any> {
    return this.http.get(
      this._settingsUrl + "getAllSupportAndBlogCategory&parent=0&aStart=0"
    );
  }

  getSupportAndBlogCategoryById(id): Observable<any> {
    return this.http.get(
      this._settingsUrl + "getSupportAndBlogCategoryById&id=" + id
    );
  }
  getSupportCategoryAndPageById(mode, id): Observable<any> {
    return this.http.get(
      this._settingsUrl + `getSupportCategoryAndPageById&mode=${mode}&id=${id}`
    );
  }
  getSupportPageById(id): Observable<any> {
    return this.http.get(this._settingsUrl + `getSupportPageById&id=${id}`);
  }

  getBreadcrumbSupportPage(mode, id): Observable<any> {
    return this.http.get(
      this._settingsUrl + `getBreadcrumbSupportPage&mode=${mode}&id=${id}`
    );
  }
  getSearchSupportPage(searchName, parent_id): Observable<any> {
    return this.http.get(
      this._settingsUrl +
        `getSearchSupportPage&searchName=${searchName}&parent_id=${parent_id}`
    );
  }

  public mainCategories = new BehaviorSubject<any>([]);
  updatemainCategories(newData: any) {
    this.mainCategories.next(newData);
  }

  getCurrentmainCategories(): Observable<any> {
    return this.mainCategories.asObservable();
  }

  // Helper function to add link tags dynamically
  addLinkTag(
    renderer: Renderer2,
    rel: string,
    href: string,
    attrName?: string,
    attrValue?: string
  ): void {
    const link: HTMLLinkElement = renderer.createElement("link");
    renderer.setAttribute(link, "rel", rel);
    renderer.setAttribute(link, "href", href);

    if (attrName && attrValue) {
      renderer.setAttribute(link, attrName, attrValue);
    }
    console.log("href", href);
    renderer.appendChild(this.document.head, link);
  }

  getDomainFromUrl(): string {
    return this.masterApiService.masterData.domain;
  }

  removeLinkTags(renderer: Renderer2) {
    const linkTags = this.document.querySelectorAll(
      'link[rel="canonical"], link[rel="alternate"]'
    );
    linkTags.forEach((linkTag: HTMLLinkElement) => {
      renderer.removeChild(this.document.head, linkTag);
    });
  }
}
